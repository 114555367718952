import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Card, CardContent, Typography, Button, Grid, CircularProgress } from '@mui/material';
import './PatientDetailsPage.css';
import { apiRequest } from '../../util';
import { useSnackbar } from '../../Contexts/SnackbarContext';
import { useUserRole } from '../../Contexts/UserRoleContext';
import DeleteIcon from '@mui/icons-material/Delete';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';

const PatientDetailsPage = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [patientData, setPatientData] = useState(null);
  const [studies, setStudies] = useState([]);
  const [histories, setHistories] = useState([]);
  const [worksheets, setWorksheets] = useState([]);
  const [reports, setReports] = useState([]);
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const { role } = useUserRole()
  const { showSnackbar } = useSnackbar();
  const [openDialog, setOpenDialog] = useState(false);
  const [studyToDelete, setStudyToDelete] = useState(null);
  const [isRefreshDisabled, setIsRefreshDisabled] = useState(false);

  const fetchPatientData = async (id) => {
      try {
        setIsPageLoading(true);
        const response = await apiRequest(`/api/patient/${id}`);
        if (response.status === 200) {
          const data = await response.json();
          setPatientData(data);
        } else {
          setPatientData(null);
        }
        setIsPageLoading(false);
      } catch (error) {
        console.error('Error fetching patient data:', error);
        setIsPageLoading(false);
      }
  };

  useEffect(() => {
    if (id) {
      fetchPatientData(id);  // Fetch patient data when the component mounts
    }
  // eslint-disable-next-line
  }, [id]);  // Run the effect whenever the ID changes

  const fetchStudies = async (id) => {
    if (!patientData) return;
    try {
      setIsLoading(true);
      const response = await apiRequest(`/api/patient/data/${id}`);
      if (response.status === 200) {
        const data = await response.json();
        setStudies(data.studies);
        setHistories(data.histories);
        setWorksheets(data.worksheets);
        setReports(data.reports)
      } else {
        setStudies([]);
        setHistories([]);
        setWorksheets([]);
        setReports([])
      }
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching studies:', error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchStudies(id);

    const interval = setInterval(() => {
      fetchStudies(id);
    }, 15000); // 60000ms = 1 minute

    return () => clearInterval(interval); // Cleanup the interval on component unmount
  // eslint-disable-next-line
  }, [patientData]);

  const handleAddHistory = () => {
    // Navigate to the add history page with the patient ID
    navigate(`/patient/${id}/add-history`);
  };

  const handleManualRefresh = async () => {
    setIsRefreshDisabled(true);
    try {
        const response = await apiRequest(`/api/patient/refresh/${id}`);
        if (response.status === 200) {
          showSnackbar('Refreshing, please wait...')
        }
      } catch (error) {
        console.error('Error fetching patient data:', error);
        showSnackbar('Failed to refresh', 'error')
      }
    setTimeout(() => {
      setIsRefreshDisabled(false);
    }, 60000);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { 
      year: 'numeric', 
      month: 'long', 
      day: 'numeric', 
      hour: '2-digit', 
      minute: '2-digit' 
    };
    return date.toLocaleDateString('en-US', options);
  };

  const handleMarkForDeletion = (studyId) => {
    setStudyToDelete(studyId);
    setOpenDialog(true);
  };

  const handleDeleteStudy = async () => {
    setOpenDialog(false);
    try {
        const response = await apiRequest(`/api/worksheet/study/${studyToDelete}`, { method: 'DELETE' });
        if (response.status === 200) {
          showSnackbar('Study removed. Please pull from machine when ready...')
          fetchStudies(id);
        }
      } catch (error) {
        showSnackbar('Failed to delete study', 'error')
    }
  };

  if (isPageLoading && !patientData) return <div>Loading...</div>;

  if (!patientData) return <div>Selected patient not found.</div>;

  return (
    <>
      <div className="patient-details">
        <Card className="patient-card">
          <CardContent>
            <Typography variant="h4" className="patient-name">
              {patientData.name.toUpperCase()}
            </Typography>
            <Typography variant="body1">MRN: {patientData.mrn}</Typography>
            <Typography variant="body1">Identifiers: {patientData.ids.join(', ')}</Typography>
            <Typography variant="body1">Gender: {patientData.gender}</Typography>
            <Typography variant="body1">Birth Date: {patientData.birth_date}</Typography>
          </CardContent>
        </Card>
        <Button
        variant="contained"
        color="primary"
        className="button"
        onClick={handleAddHistory}
        >
        Add History
        </Button>
        <Button
        variant="contained"
        color="primary"
        className="button"
        sx={{mt: 2}}
        onClick={handleManualRefresh}
        disabled={isRefreshDisabled}
        >
        Read From Machine
        </Button>
        {isLoading && <CircularProgress className="loading-spinner" />}
        <Typography variant="h5" className="title">
          Recent Studies
        </Typography>
          {studies.length > 0 ? (
            <Grid container spacing={3} className="studies-grid">
            {studies.map((study) => {
              const isToday = new Date(study.study_date).toDateString() === new Date().toDateString();
              return (
                <Grid item xs={12} sm={6} md={6} key={study.id}>
                  <Card className="study-card" style={{ textDecoration: 'none', opacity: isToday ? 1 : 0.5 }}>
                    <CardContent>
                      <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
                      <Typography variant="h6">{formatDate(study.study_date)}</Typography>
                      <DeleteIcon fontSize="small" sx={{color: 'red', cursor: 'pointer'}} onClick={() => handleMarkForDeletion(study.id)}/>
                      </div>
                      <Typography variant="body1">{study.study_description}</Typography>
                      <Typography variant="body1">Accession: {study.accession}</Typography>
                      <Typography variant="body1">Institution: {study.institution_name}</Typography>
                      {/* Add a heading or divider for precision ranges */}
                      <Typography variant="subtitle1" style={{ marginTop: '10px', fontWeight: 'bold' }}>
                        Precision Ranges for Next Study (2%)
                      </Typography>
                      {study.current_ap_spine && study.current_ap_spine[0] !== null && (
                        <Typography variant="body1">
                          AP Spine: {study.current_ap_spine[0]} - {study.current_ap_spine[1]} &nbsp;
                          (Scan Mode: {study.apSpineScanMode})
                        </Typography>
                      )}
                      {study.current_left_femur && study.current_left_femur[0] !== null && (
                        <Typography variant="body1">
                          Left Femur: {study.current_left_femur[0]} - {study.current_left_femur[1]} &nbsp;
                          (Scan Mode: {study.leftFemurScanMode})
                        </Typography>
                      )}
                      {study.current_left_forearm && study.current_left_forearm[0] !== null && (
                        <Typography variant="body1">
                          Left Forearm: {study.current_left_forearm[0]} - {study.current_left_forearm[1]} &nbsp;
                          (Scan Mode: {study.leftForearmScanMode})
                        </Typography>
                      )}
                      {study.current_right_femur && study.current_right_femur[0] !== null && (
                        <Typography variant="body1">
                          Right Femur: {study.current_right_femur[0]} - {study.current_right_femur[1]}  &nbsp;
                          (Scan Mode: {study.rightFemurScanMode})
                        </Typography>
                      )}
                      {study.current_right_forearm && study.current_right_forearm[0] !== null && (
                        <Typography variant="body1">
                          Right Forearm: {study.current_right_forearm[0]} - {study.current_right_forearm[1]} &nbsp;
                            (Scan Mode: {study.rightForearmScanMode})
                        </Typography>
                      )}
                      {study.current_total_body && study.current_total_body[0] !== null && (
                        <Typography variant="body1">
                          Total Body: {study.current_total_body[0]} - {study.current_total_body[1]} &nbsp;
                          (Scan Mode: {study.totalBodyScanMode})
                        </Typography>
                      )}
                      <a href={`/study/${study.id}`} style={{ textDecoration: 'none' }}>
                        <Button
                          variant="contained"
                          color="primary"
                          className="button"
                          sx={{mt: 2}}
                        >
                          New Worksheet
                        </Button>
                      </a>
                    </CardContent>
                  </Card>
                </Grid>
              );
            })}
            </Grid>
          ) : (
          <Typography variant="body1">No recent studies found.</Typography>
          )}
        <Typography variant="h5" className="title">
          Recent Histories
        </Typography>
        {histories.length > 0 ? (
          <Grid container spacing={3} className="histories-grid">
            {histories.map((history) => {
              const isToday = new Date(history.date).toDateString() === new Date().toDateString();
              return (
                <Grid item xs={12} sm={6} md={6} key={history.id}>
                  <a href={`/history/${history.id}`} rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
                    <Card className="study-card" style={{ opacity: isToday ? 1 : 0.5 }}>
                      <CardContent>
                        <Typography variant="h6">{formatDate(history.date)}</Typography>
                        <Typography variant="body1">Technologist: {history.author}</Typography>
                      </CardContent>
                    </Card>
                  </a>
                </Grid>
              );
            })}
          </Grid>
        ) : (
          <Typography variant="body1">No recent histories found.</Typography>
        )}
        <Typography variant="h5" className="title">
          Recent Worksheets
        </Typography>
        {worksheets.length > 0 ? (
          <Grid container spacing={3} className="medical-reports-grid">
            {worksheets.map((worksheet) => {
              const isToday = new Date(worksheet.date).toDateString() === new Date().toDateString();
              return (
                <Grid item xs={12} sm={6} md={6} key={worksheet.id}>
                  <a href={`/worksheet/${worksheet.id}`} rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
                    <Card className="study-card" style={{ opacity: isToday ? 1 : 0.5 }}>
                      <CardContent>
                        <Typography variant="h6">{formatDate(worksheet.date)}</Typography>
                        <Typography variant="body1">Accession: {worksheet.accession}</Typography>
                        <Typography variant="body1">Technologist: {worksheet.author}</Typography>
                      </CardContent>
                    </Card>
                  </a>
                </Grid>
              );
            })}
          </Grid>
        ) : (
          <Typography variant="body1">No recent worksheets found.</Typography>
        )}
        {['Radiologist', 'Admin', 'Super User'].includes(role) && (
          <>
        <Typography variant="h5" className="title">
          Recent Reports
        </Typography>
        {reports.length > 0 ? (
          <Grid container spacing={3} className="reports-grid">
            {reports.map((report) => {
              const isToday = new Date(report.date_produced).toDateString() === new Date().toDateString();
              return (
                <Grid item xs={12} sm={6} md={6} key={report.id}>
                  <a href={`/report/${report.study_uid}`} rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
                    <Card className="study-card" style={{ opacity: isToday ? 1 : 0.5 }}>
                      <CardContent>
                        <Typography variant="h6">{formatDate(report.date_produced)}</Typography>
                        <Typography variant="body1">Study Date: {report.study_date}</Typography>
                        <Typography variant="body1">Accession: {report.accession_number}</Typography>
                        <Typography variant="body1">Diagnostic Category: {report.bmd_diagnostic_category}</Typography>
                        <Typography variant="body1">Fracture Risk: {report.bmd_fracture_risk}</Typography>
                      </CardContent>
                    </Card>
                  </a>
                </Grid>
              );
            })}
          </Grid>
        ) : (
          <Typography variant="body1">No recent reports found.</Typography>
        )}
        </>
        )}
      </div>
      <Dialog
        open={openDialog}
        onClose={handleDeleteStudy}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirm Deletion"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this study? You must reload it from the machine manually
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteStudy} color="primary">
            Confirm
          </Button>
          <Button onClick={handleDeleteStudy} color="primary" autoFocus>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default PatientDetailsPage;
